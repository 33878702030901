/** @jsx jsx */
import { Box, Grid, jsx, Text } from "theme-ui";
//import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  FaArrowCircleLeft,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaTicketAlt,
  FaFileDownload,
} from "react-icons/fa";
import ReactPlayer from "react-player/lazy";
import ShareIcons from "../../components/ShareIcons";
import SEO from "../../components/Seo";

export const pageQuery = graphql`
  query EventPageQuery($slug: String!) {
    graphCmsEvent(slug: { eq: $slug }) {
      date
      endDate
      regularEvent
      regularEventDay
      eventUrl
      location
      slug
      title
      time
      type
      ticketPrice
      videoUrl
      body {
        html
        raw
      }
      featuredImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          aspectRatio: 1.97
        )
      }
      eventDownloads {
        url
        fileName
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const EventPage = ({ data, location }) => {
  console.log("data", data);
  //grab banner image
  const bannerImage = getImage(data.graphCmsEvent.featuredImage);
  //format date
  let event_date = new Date(data.graphCmsEvent.date);
  let event_date_long = event_date;
  let event_day = data.graphCmsEvent.regularEventDay;
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
  });
  const dateTimeFormatLong = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  event_date = dateTimeFormat.format(event_date);
  event_date_long = dateTimeFormatLong.format(event_date_long);
  //remove underscores from event type
  const event_type = data.graphCmsEvent.type.replace(/_|-/g, " ");
  return (
    <Layout>
      <SEO title={`${data.graphCmsEvent.title} | Events`} />
      <Box className="banner" sx={bannerStyles}>
        <GatsbyImage image={bannerImage} alt="" />
        <Box className="bannerTitle">
          <Box>
            <h2>{event_type}</h2>
            <h3>{data.graphCmsEvent.regularEvent ? event_day : event_date}</h3>
          </Box>
        </Box>
      </Box>

      <Grid
        gap={5}
        columns={[1, "2fr 1fr"]}
        className="mainPage"
        sx={mainStyles}
      >
        <Box className="body">
          <h1>{data.graphCmsEvent.title}</h1>
          <h3>{data.graphCmsEvent.regularEvent ? event_day : event_date}</h3>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={{ __html: data.graphCmsEvent.body.html }}
          />
          {data.graphCmsEvent.videoUrl && (
            <Box className="videoPlayer">
              <ReactPlayer
                url={data.graphCmsEvent.videoUrl}
                width="100%"
                height="100%"
              />
            </Box>
          )}

          <Box className="bodyButtons">
            <Link to="/events">
              <FaArrowCircleLeft alt="" />
              <span>Back to Events List</span>
            </Link>
          </Box>
        </Box>
        <aside sx={sidebarStyles}>
          <h2>Event Information</h2>
          <Box>
            <Box>
              <FaCalendarAlt alt="" />
              <h3>When</h3>
            </Box>

            <Text>{event_date_long}</Text>
            {data.graphCmsEvent.time && <Text>{data.graphCmsEvent.time}</Text>}
          </Box>
          <Box>
            <Box>
              <FaMapMarkerAlt alt="" />
              <h3>Where</h3>
            </Box>

            {data.graphCmsEvent.eventUrl ? (
              <a href={data.graphCmsEvent.eventUrl}>
                {data.graphCmsEvent.location}
              </a>
            ) : (
              <Text>{data.graphCmsEvent.location}</Text>
            )}
          </Box>
          {data.graphCmsEvent.ticketPrice && (
            <Box>
              <Box>
                <FaTicketAlt alt="" />
                <h3>Ticket Price</h3>
              </Box>

              <Text>{data.graphCmsEvent.ticketPrice}</Text>
            </Box>
          )}
          {data.graphCmsEvent.eventDownloads.length > 0 && (
            <Box>
              <Box>
                <FaFileDownload alt="" />
                <h3>Downloads</h3>
              </Box>
              <Box>
                {data.graphCmsEvent.eventDownloads.map((item, index) => {
                  return (
                    <a key={`download-${index}`} href={item.url}>
                      {item.fileName}
                    </a>
                  );
                })}
              </Box>
            </Box>
          )}
          <Box>
            <Box>
              <ShareIcons
                type="Event"
                url={data.site.siteMetadata.siteUrl + location.pathname}
              />
            </Box>
          </Box>
        </aside>
      </Grid>
    </Layout>
  );
};

export default EventPage;

const bannerStyles = {
  "&.banner": {
    display: "grid",
    "& .gatsby-image-wrapper": {
      gridArea: "1/1",
    },
    "& .bannerTitle": {
      gridArea: "1/1",
      position: "relative",
      display: "grid",
      div: {
        position: "absolute",
        bottom: 0,
        left: 4,
        display: ["flex"],
        h2: {
          p: 2,
          color: "white",
          border: "1px solid white",
          bg: "rgba(0,0,0,0.4)",
          fontSize: 3,
          my: 3,
        },
        h3: {
          display: ["none", "inline"],
          p: 2,
          color: "white",
          border: "1px solid white",
          borderLeft: "none",
          bg: "house3",
          fontSize: 3,
          my: 3,
        },
      },
    },
  },
};

const mainStyles = {
  "&.mainPage": {
    mx: [3, 4],
    "& .body": {
      pr: [0, 3],
      mb: [0, 4],
      fontSize: 3,
      h1: {
        lineHeight: ["1.15", "normal"],
        mb: [0, 4],
      },
      h3: {
        //hide when not mobile
        display: ["inline-block", "none"],
        p: 2,
        color: "white",
        border: "1px solid white",
        borderLeft: "none",
        bg: "house3",
        fontSize: 3,
        mb: [2, 3],
      },
      "& .bodyText": {
        mb: 4,
      },
      "& .videoPlayer": {
        mb: 4,
        position: "relative",
        paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
        "& > div": {
          position: "absolute",
          top: "0",
          left: "0",
        },
      },
      "& .bodyButtons": {
        a: {
          color: "black",
          textDecoration: "none",
          position: "relative",
          transition: "all 0.3s ease-in-out",
          svg: {
            color: "house3",
            mr: 2,
            fontSize: 4,
            position: "absolute",
            marginTop: "2px",
          },
          span: {
            marginLeft: "25px",
            color: "black",
            px: 2,
            transition: "all 0.3s ease-in-out",
          },
          "&:hover, &:focus": {
            span: {
              bg: "house3",
              color: "white",
            },
          },
        },
      },
    },
  },
};

const sidebarStyles = {
  h2: {
    mt: [0, "42px"],
    mb: 4,
  },
  "& > div": {
    mb: 4,
    "& > div": {
      display: "flex",
      svg: {
        color: "house3",
        fontSize: 3,
        mt: "2px",
      },
      h3: {
        mt: 0,
        ml: "10px",
        fontSize: 2,
      },
    },
    span: {
      display: "block",
      ml: 2,
    },
    a: {
      color: "black",
      px: 2,
      "&:hover": {
        bg: "house3",
        color: "white",
        textDecoration: "none",
      },
    },
  },
};
